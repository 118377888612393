
.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px;
  border-radius: 10px;

  transition: background-color 0.5s;
}
.contact:hover {
  background-color: var(--hover-color);
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 150px;
  height: 150px;
  margin-bottom: 30px;
}
.emailIcon{
  width: 100%;
  background-color: white;
}

.linkedInIcon {
  width: 100%;
  border-radius: 25px;
  background-color: white;
}

.iconContainer:hover {
  animation: nudge 0.5s;
}
@keyframes nudge {
  from {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-5deg);
  }
  66% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.header {
  font-size: 48px;
  margin-bottom: 20px;
}

.info {
  font-size: 24px;
  font-weight: bold;

  margin-bottom: 20px;
}

.link {
  color: var(--primary-color);
  background-color: inherit;

  font-size: 20px;
  font-weight: bold;
}
.link:hover {
  cursor: pointer;
}

@media (min-width : 750px) {
  .contactPage {
    flex-direction: row;
  }
}

@media (min-width : 620px) {
  .contactPage {
    min-height: calc(100vh - var(--title-height));
  }
}