
/* ###### Variables */
:root {
  /* For NavBar Sizing */ 
  --link-height: 40px;
  --title-height: calc(var(--link-height) * 2);

  /* Color Scheme */
  --primary-color: blue;
  --hover-color: #EDEDED;
  --text-color: #535353;
}

/* ###### Custom Scrollbar */
html {
  overflow-y: overlay;
}
::-webkit-scrollbar {
  opacity: 0;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  opacity: 0;
  background-color: gray;
  border-radius: 2.5px;
}

/* ###### Global Styles */

/* Reset */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;

  box-sizing: border-box;

  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  text-align: center;
}

#root {
  min-height: 100vh;
}

h1 {
  font-size: 2em;
}

a {
  text-decoration: none;
}

code {
  background: #E0E0E0;
  font-family: monospace;
  padding: 0 10px;
}