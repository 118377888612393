:root{
  --temp: none;
}


.homePage {
  display: grid;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 25px 0 25px;
  border-radius: 10px;
  transition: background-color 0.5s;
}
.section:hover {
  background-color: var(--hover-color);
}

/* ################## SECTION 1 */
.profile {
  background-color: var(--temp);
  margin: 25px 0 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.photoContainer{
  width: 60%;
  aspect-ratio: 1;
  border-radius: 10%;
  background-color: #C3C3C3;
}
.photo {
  vertical-align:top;
  width: 100%;
  border-radius: 10%;
}
.jobTitle {
  margin-top: 50px;
}
.jobDate {
  margin-top: 20px;
}

/* ################## SECTION 2 */
.list {
  background-color: var(--temp);
  list-style-type : none;

  height: 500px;
  width: 100%;
  margin: 150px 0 150px 0;

  display : flex;
  flex-direction : column;
  justify-content : space-between;
}
.listItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.icons {
  margin-top: 20px;
  width : 50px;
  height: 50px;
}
.icons:hover {
  animation: nudge 0.5s;
}
@keyframes nudge {
  from {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-5deg);
  }
  66% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* ################## SECTION 3 */
.bio {
  font-size: 25px;
  margin: 75px 0 75px 0;

  background-color: var(--temp);
}
.paragraph {
  margin-top: 40px;
}

/* ################## Other */

.backToTop{
  width: 150px;
  height: 50px;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  outline: 1px grey solid;
  justify-self: center;
  margin: 50px 0 50px 0;
  cursor: pointer;
}
.backToTop:hover {
  background-color: #EDEDED;
}

@media (min-width : 1000px) {
  .homePage {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .profile {
    margin-top: 50px;
  }
  .photoContainer{
    width: 100%;
  }
  .backToTop {
    display: none;
  }
}
@media (min-width : 620px) {
  .homePage {
    min-height: calc(100vh - var(--title-height));
  }
}