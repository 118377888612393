
/* Main navbar styles #######################################################*/
.navbar {
  display : flex;
  flex-direction: column;
  align-items : center;
  justify-content: space-between;

  height : calc(var(--title-height) + var(--link-height));

  transition: background-color 0.5s;
}
/* .navbar:hover {
  background-color:burlywood;
} */

/* Title Styles #######################################################*/
.title {
  font-size: calc(var(--title-height) * 0.5);
  line-height: var(--title-height);

  text-decoration: none;
  color: black;
  
  height: var(--title-height);
}

/* Link Container Styles #######################################################*/
.links {
  display : flex;

  height: var(--link-height);
}

/* Link Styles #######################################################*/
.link {
  text-decoration: none; /* Removes underline from default <a> tag stylings */
  color: black;
  font-size: calc(var(--link-height) * 0.5);
  line-height: var(--link-height);

  height: 100%;
  padding: 0 20px;


  transition: background-color 0.5s;
}
.link:hover {
  background-color: var(--hover-color);
}

/* Responsive Styles #######################################################*/
@media (min-width : 620px) {
  .navbar {
    height: var(--title-height);
    flex-direction: row;
    padding-left: 50px;
    padding-right: 2vw;
  }
  .links {
    height: var(--title-height);
  }
  .link {
    line-height: var(--title-height);
  }
}