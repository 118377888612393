
.NoPage {
  display: grid;
}

.message {
  margin: auto;
  padding-top: 20vh;
}


@media (min-width : 620px) {
  .NoPage {
    min-height: calc(100vh - var(--title-height));
  }
  .message{
    padding-top: 0;
    padding-bottom: 100px;
  }
}